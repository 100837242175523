import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Representative, UserModel } from '../../../shared/models';
import { EmployeeFilterExtend, EmployeesService, GenerateEmployeeResponse } from '../../services/employees.service';
import {
  SetAssignUserToEmployee,
  ChangeDates,
  ClearGeneratedEmployee,
  DeleteAttachment,
  DownloadAttachment,
  DownloadDocument,
  DownloadMultipleAttachments,
  GenerateEmployee,
  GetAllEmployees,
  GetAttachments,
  GetRepresentativeListForFilter,
  GetUserListToAssignEmployee,
  HhaExchangeSync,
  OpenGenerateEmployee,
  ResetAdminPanel,
  SendEmail,
  SendMessage,
  SetNoteEmployee,
  UpdateAttachment,
  UploadDocument,
  DeleteUserToAssignEmployee,
  GetUserListToAssignFilterEmployee,
  GetAllMedicalAttachmentsEmployee,
  UpdateMedicalAttachmentsEmployee,
  DeleteMedicalAttachmentsEmployee,
  DownloadMedicalAttachmentsEmployee,
  DashboardEnableDatatableLoading,
} from './employees.actions';
import { finalize, take, tap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { MessagePopupComponent } from '../../../popups/message-popup/message-popup.component';
import { HistoryStateModel } from '../document-history/history.state';
import { PopupFactoryService } from '../../../popups/popup-factory.service';
import { forkJoin, Observable, timer } from 'rxjs';
import { ChooseDepartmentComponent } from '../../../popups/choose-department/choose-department.component';
import { AttachmentsService } from '../../services/attachments.service';
import { Injectable } from '@angular/core';
import { openLink } from '../../../shared/helpers/open-link';
import { MedicalService } from '../../services/medical.service';
import { convertDateMDY, convertMomentDate } from '../../../shared/helpers/date-format';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';
import { EmployeeFilter } from '../filters/filters.models';
import { isImageExt } from '../../../shared/helpers/is-image';

export interface EmployeesStateModel {
  list: any[];
  attachmentsList: any[];
  page: number;
  editFormValue: any;
  total: number;
  totalAtt: number;
  salesList: any[];
  usersList: any[];
  usersListFilter: any[];
  salesListForFilter: Array<{ id: string; uid: string; name: string }>;
  meta: {
    isLoading: boolean;
  };
  random: {
    uid: string;
    password: string;
    autoLoginLink: string;
    loginPageLink: string;
  };
}

export const defaultState: EmployeesStateModel = {
  list: [],
  attachmentsList: [],
  total: 0,
  totalAtt: 0,
  page: 1,
  editFormValue: {},
  salesList: [],
  salesListForFilter: [],
  usersList: [],
  usersListFilter: [],
  random: {
    uid: '',
    password: '',
    autoLoginLink: '',
    loginPageLink: '',
  },
  meta: {
    isLoading: true,
  },
};

@State({
  name: 'employees',
  defaults: defaultState,
})
@Injectable()
export class EmployeesState {
  constructor(
    private api: EmployeesService,
    private medicalService: MedicalService,
    private userService: UserService,
    private popup: PopupFactoryService,
    private attachments: AttachmentsService,
    private store: Store,
  ) {}

  @Selector()
  static employeeList(state): any[] {
    return state.list;
  }

  @Selector()
  static isLoading(state): boolean {
    return state.meta.isLoading;
  }

  @Selector()
  static salesList(state): any[] {
    return state.salesList;
  }

  @Selector()
  static representativeListForFilter(state): Representative[] {
    return state.salesListForFilter;
  }

  @Selector()
  static usersToAssign(state): any[] {
    return state.usersList;
  }

  @Selector()
  static usersToAssignFilter(state): any[] {
    return state.usersListFilter;
  }

  @Selector()
  static random(state): any {
    return state?.random;
  }

  @Selector()
  static page(state): number {
    return state.page;
  }

  @Selector()
  static total(state): number {
    return state.total;
  }

  @Selector()
  static totalAtt(state): number {
    return state.totalAtt;
  }

  @Selector()
  static attachments(state): any {
    return state.attachmentsList.map((item: any) => ({
      ...item,
      path: isImageExt(item.ext) ? item.path : '/assets/img/no_preview.svg',
      isImage: isImageExt(item.ext),
    }));
  }

  @Action(GetAllEmployees)
  getAllEmployees(ctx: StateContext<EmployeesStateModel>): Observable<any> {
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
    const filtersValue: EmployeeFilter = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.Employees].model);
    const obj: EmployeeFilterExtend = {
      ...filtersValue,
      dateFrom: convertMomentDate(filtersValue.date.from),
      dateTo: convertMomentDate(filtersValue.date.to),
    };
    delete obj.date;

    return this.api.getAll({ ...obj }).pipe(
      tap(({ data, total }) => {
        ctx.patchState({
          list: data,
          total,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(GetRepresentativeListForFilter)
  GetSalesListForFilter(ctx: StateContext<EmployeesStateModel>, { search }): Observable<Representative[]> {
    return this.userService.getAllForFilter({ rows: 1000, search }).pipe(
      tap(({ users }) =>
        ctx.patchState({
          salesListForFilter: users.map(user => {
            return {
              id: user.id + '/sales',
              uid: user.uid,
              name: user.name.trim(),
            };
          }),
        }),
      ),
    );
  }

  @Action(OpenGenerateEmployee)
  openGenerateEmployee(ctx: StateContext<EmployeesStateModel>): Observable<any> {
    return this.popup
      .createPopup({
        popupComponent: ChooseDepartmentComponent,
        preventBgClick: true,
      })
      .pipe(
        tap(() => ctx.dispatch(new ClearGeneratedEmployee())),
        take(1),
      );
  }

  @Action(GenerateEmployee)
  generateEmployee(ctx: StateContext<EmployeesStateModel>, { employeeData }: GenerateEmployee): Observable<any> {
    return this.api.generateEmployee(employeeData).pipe(
      tap((res: GenerateEmployeeResponse) => {
        ctx.patchState({
          random: {
            uid: res.id,
            password: res.password,
            autoLoginLink: res.autoLoginLink,
            loginPageLink: res.loginPageLink,
          },
        });
      }),
    );
  }

  @Action(ClearGeneratedEmployee)
  clearGeneratedEmployee(ctx: StateContext<EmployeesStateModel>): void {
    ctx.patchState({
      random: defaultState?.random,
    });
  }

  @Action(SendEmail)
  sendEmail(ctx: StateContext<EmployeesStateModel>, { email }: SendEmail): Observable<any> {
    const state: EmployeesStateModel = ctx.getState();
    return this.api.sendEmail(email, state?.random);
  }

  @Action(SendMessage)
  sendMessage(ctx: StateContext<EmployeesStateModel>, { phone }: SendMessage): Observable<any> {
    const state: EmployeesStateModel = ctx.getState();
    return this.api.sendMessage(phone, state?.random);
  }

  @Action(DownloadDocument)
  downloadDocument(ctx: StateContext<EmployeesStateModel>, { uid, historyId, format }: DownloadDocument): Observable<any> {
    const currentUser: UserModel = this.store.selectSnapshot(({ app }) => app.currentUser);
    return this.api.downloadDocument(uid, currentUser?.roles[0]?.id, format, historyId).pipe(
      tap(({ link }: any) => {
        if (link) {
          openLink(link);
        }
      }),
    );
  }

  @Action(ResetAdminPanel)
  resetList(ctx: StateContext<EmployeesStateModel>): void {
    ctx.setState(defaultState);
  }

  @Action(HhaExchangeSync)
  hhaExchangeSync(ctx: StateContext<HistoryStateModel>, { userIds }: HhaExchangeSync): Observable<any> {
    return forkJoin(userIds.map((id: string) => this.api.hhaExchangeSync(id))).pipe(
      tap(() => {
        this.popup
          .createPopup({
            popupComponent: MessagePopupComponent,
            preventBgClick: true,
            popupData: {
              message: 'Successfully sent to HHAEx',
              closeRoute: null,
            },
          })
          .subscribe();
        ctx.dispatch(new GetAllEmployees());
      }),
    );
  }

  @Action(ChangeDates)
  changeDates(ctx: StateContext<EmployeesStateModel>, { uuid, newDate, typeDate }: ChangeDates): Observable<any> {
    return this.api.changeDocumentDate(uuid, newDate, typeDate);
  }

  @Action(GetAttachments)
  getAttachmentsList(ctx: StateContext<EmployeesStateModel>, { key }): Observable<any> {
    const filtersKey: FiltersKeyEnum =
      key === FiltersKeyEnum.EmployeeAttachments ? FiltersKeyEnum.EmployeeAttachments : FiltersKeyEnum.EmployeeAttachmentsApp;

    const filterValue = this.store.selectSnapshot(({ filters }) => filters[filtersKey].model);
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
    let query: any = {};
    if (filterValue.date) {
      query = {
        dataStart: convertDateMDY(filterValue.date.from, 'YYYY-MM-DD'),
        dataEnd: convertDateMDY(filterValue.date.to, 'YYYY-MM-DD'),
      };
    }
    Object.keys(filterValue || {}).forEach((filterKey: string) => {
      if (filterValue[filterKey] !== '' && filterValue[filterKey] !== null && filterKey !== 'date') {
        query[filterKey] = filterValue[filterKey];
      }
    });
    if (query.hhaExchange !== 0 && query.hhaExchange !== 1) {
      delete query.hhaExchange;
    }
    return this.attachments.getAttachments(query).pipe(
      tap((attObj: any) => {
        ctx.patchState({
          attachmentsList: attObj.attachmentsList,
          totalAtt: attObj.totalAtt,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(DeleteAttachment)
  deleteAttachments(ctx: StateContext<EmployeesStateModel>, { attachmentId }: DeleteAttachment): Observable<void> {
    return this.attachments.deleteAttachment(attachmentId);
  }

  @Action(DownloadAttachment)
  downloadAttachment(ctx: StateContext<EmployeesStateModel>, { attachmentIds }: DownloadAttachment): Observable<any> {
    return this.attachments.getAttachmentLink(attachmentIds).pipe(
      tap((res: any) => {
        if (res?.link || res?.file.url) {
          openLink(res.link || res.file.url);
        }
      }),
    );
  }

  @Action(DownloadMultipleAttachments)
  downloadMultipleAttachments(ctx: StateContext<EmployeesStateModel>, { ids }: DownloadMultipleAttachments): Observable<any> {
    return this.attachments.getAttachmentLink(ids).pipe(
      tap(({ link }: any) => {
        if (link) {
          openLink(link);
        }
      }),
    );
  }

  @Action(UploadDocument)
  uploadDocument(ctx: StateContext<EmployeesStateModel>, { title, file }: UploadDocument): Observable<any> {
    return this.attachments.postAttachment({ title, file });
  }

  @Action(UpdateAttachment)
  updateAttachment(ctx: StateContext<EmployeesStateModel>, { attachmentId, title }: UpdateAttachment): Observable<any> {
    return this.attachments.updateAttachment(attachmentId, { title });
  }

  @Action(SetNoteEmployee)
  setNote(ctx: StateContext<EmployeesStateModel>, { uid, text }: SetNoteEmployee): void {
    const state: EmployeesStateModel = ctx.getState();
    const list: any[] = [...state.list];
    const employee = list.find(e => e.uid === uid);

    if (text) {
      if (employee.notes) {
        employee.notes.text = text;
      } else {
        employee.notes = {
          uid,
          text,
        };
      }
    }
    ctx.patchState({ list });
  }

  @Action(GetUserListToAssignEmployee)
  getUserListToAssign(ctx: StateContext<EmployeesStateModel>, { search }: GetUserListToAssignEmployee): Observable<any> {
    return this.api.getUserList(search, 0).pipe(
      tap(users => {
        ctx.patchState({
          usersList: users,
        });
      }),
    );
  }

  @Action(GetUserListToAssignFilterEmployee)
  getUserListToAssignFilter(ctx: StateContext<EmployeesStateModel>, { search }: GetUserListToAssignFilterEmployee): Observable<any> {
    return this.api.getUserList(search, 1).pipe(
      tap(users => {
        ctx.patchState({
          usersListFilter: users,
        });
      }),
    );
  }

  @Action(SetAssignUserToEmployee)
  assignUserToEmployee(ctx: StateContext<EmployeesStateModel>, { uid, assignIds }: SetAssignUserToEmployee): Observable<any> {
    return this.api.assignedUser(uid, assignIds);
  }

  @Action(DeleteUserToAssignEmployee)
  deleteAssigned(ctx: StateContext<EmployeesStateModel>, { employeeId, userId }: DeleteUserToAssignEmployee): Observable<void> {
    return this.api.deleteAssignedUser(employeeId, userId);
  }

  @Action(GetAllMedicalAttachmentsEmployee)
  getMedicalAttachmentsEmployeeList(ctx: StateContext<EmployeesStateModel>, { uid, isParent }): Observable<any> {
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
    const filtersValue = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.MedicalAttachments].model);
    return this.medicalService.getMedicalAttachmentsEmployee(uid, filtersValue, isParent).pipe(
      tap(({ data, total }) => {
        ctx.patchState({
          attachmentsList: data,
          totalAtt: total,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(UpdateMedicalAttachmentsEmployee)
  updateMedicalAttachmentsByUid(ctx: StateContext<EmployeesStateModel>, { uid, attId, title }): Observable<any> {
    return this.medicalService.updateMedicalAttachmentsEmployee(uid, attId, title);
  }

  @Action(DeleteMedicalAttachmentsEmployee)
  deleteMedicalAttachmentsByUid(ctx: StateContext<EmployeesStateModel>, { uid, attId }: DeleteMedicalAttachmentsEmployee): Observable<any> {
    return this.medicalService.deleteMedicalAttachmentsEmployee(uid, attId);
  }

  @Action(DownloadMedicalAttachmentsEmployee)
  downloadMedicalAttachmentsByUid(ctx: StateContext<EmployeesStateModel>, { uid, attIds }): Observable<any> {
    return this.medicalService.downloadMedicalAttachmentsEmployee(uid, attIds).pipe(
      tap((res: any) => {
        if (res?.path) {
          openLink(res.path);
        }
        if (res?.link) {
          openLink(res.link);
        }
      }),
    );
  }

  @Action(DashboardEnableDatatableLoading)
  dashboardEnableDatatableLoading(ctx: StateContext<any>): void {
    ctx.patchState({
      meta: {
        isLoading: true,
      },
    });
  }

  private disableDataTableLoading(ctx: StateContext<any>): void {
    timer(1000)
      .pipe(take(1))
      .subscribe(() => {
        ctx.patchState({
          meta: {
            isLoading: false,
          },
        });
      });
  }
}
